import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import formatDate from '../../utils/formatDate'
import * as styles from './FeaturedNews.module.scss'

export default function FeaturedNews({ data }) {
  var featured = []
  var articles = []

  return (
    <div>
      <StaticQuery
        query={graphql`
          query FeaturedQuery {
            sanityNewsSettings {
              featuredArticles {
                title
              }
            }
            allSanityArticle(sort: { fields: [date], order: DESC }) {
              nodes {
                date
                title
                excerpt
                video
                typeCheck
                slug {
                  current
                }
                topics {
                  title
                }
                categories {
                  title
                }
                image {
                  asset {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        `}
        render={(featuredData) => {
          featuredData.sanityNewsSettings.featuredArticles.forEach((feat) => {
            featured.push(feat.title)
          })

          featuredData.allSanityArticle.nodes.forEach((article, index) => {
            var cats = []
            for (var i = 0; i < featured.length; i++) {
              if (article.title === featured[i]) {
                var date = article.date
                var slug = article.slug.current
                var media = []

                //check if there is a video and if we are getting its thumbnail or not
                if (article.video !== null) {
                  var url = article.video
                  var match = /vimeo.*\/(\d+)/i.exec(url)
                  var id = match[1]
                  var src = 'https://vumbnail.com/' + id + '.jpg'
                  if (article.typeCheck === 'vimeo') {
                    media.push(
                      <div className={styles.mediaCover} key={index + 'featuredVimeo'}>
                        <img className={styles.thumbnail} src={src} alt="thumbnail" />
                        <StaticImage
                          className={styles.playBtn}
                          src="../../images/play-icon.png"
                          alt="play"
                        ></StaticImage>
                      </div>
                    )
                  } else {
                    media.push(
                      <div className={styles.mediaCover} key={index + 'featuredThumbnail'}>
                        <StaticImage
                          className={styles.playBtn}
                          src="../../images/play-icon.png"
                          alt="play"
                        ></StaticImage>
                        {article.image && (
                          <div>
                            <GatsbyImage alt={article.title} image={article.image.asset.gatsbyImageData}></GatsbyImage>
                          </div>
                        )}
                      </div>
                    )
                  }
                } else {
                  media.push(
                    <div className={styles.mediaCover} key={index + 'featuredImage'}>
                      {article.image && (
                        <div>
                          <GatsbyImage alt={article.title} image={article.image.asset.gatsbyImageData}></GatsbyImage>
                        </div>
                      )}
                    </div>
                  )
                }

                articles.push(
                  <Link to={'/news/' + slug} id="feature-card" key={slug + 'featuredCard'}>
                    <div className={styles.featuredCard}>
                      {media}

                      <div className={styles.contentWrap}>
                        {date && <h4>{formatDate(date)}</h4>}
                        <h2>{article.title}</h2>
                        <p>{article.excerpt}</p>

                        <div className={styles.categories}>
                          {article.topics.forEach((topic, index) => {
                            if (index < 2) {
                              cats.push(<span key={index + 'spanTopic'}>{topic.title}</span>)
                            }
                          })}

                          {article.categories.forEach((topic, index) => {
                            if (index < 2) {
                              cats.push(<span key={index + 'spanCategory'}>{topic.title}</span>)
                            }
                          })}
                          {cats}
                        </div>
                      </div>
                    </div>
                  </Link>
                )
              }
            }
          })
        }}
      />

      <div className={styles.featuredNewsWrapper} id="featured-wrapper">
        <span className={styles.featuredBlock} id="block-head">
          Featured
        </span>

        <div className={styles.featuredGrid}>{articles}</div>
      </div>
    </div>
  )
}
