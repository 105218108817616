import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { RiCloseFill } from 'react-icons/ri'
import { StaticImage } from 'gatsby-plugin-image'
import formatDate from '../../utils/formatDate'
import * as styles from './AllNews.module.scss'

export default function AllNews({ data }) {
  const isBrowser = typeof window !== 'undefined'
  if (isBrowser) {
    var url = window.location.href
    var slug = url.split('/news/').pop()
    var parentFilter = slug.substr(0, slug.indexOf('/'))
    var filteredItem = url.split('/news/' + parentFilter + '/').pop()
    var filteredItemF = filteredItem.substr(0, filteredItem.indexOf('/'))
    var filtered = ''
    var main = []

    if (url.includes('page')) {
      filtered = filteredItemF.replace(/-/g, ' ')
    } else {
      filtered = filteredItem
    }

    var articles = []

    if (data.articles.nodes.length === 0) {
      articles.push(<h2 className={styles.noResults}>Sorry, There is no results</h2>)
    } else {
      data.articles.nodes.forEach((article, index) => {
        var cats = []
        var date = article.date
        var slug = article.slug?.current
        var topicCount = 0
        var catCount = 0
        var media = []

        //check if there is a video and if we are getting its thumbnail or not
        if (article.video !== null) {
          var url = article.video
          var match = /vimeo.*\/(\d+)/i.exec(url)
          var id = match[1]
          var src = 'https://vumbnail.com/' + id + '.jpg'
          if (article.typeCheck === 'vimeo') {
            media.push(
              <div className={styles.mediaCover} key={index + 'vimeo'}>
                <img className={styles.thumbnail} src={src} alt="thumbnail" />
                <StaticImage className={styles.playBtn} src="../../images/play-icon.png" alt="play"></StaticImage>
              </div>
            )
          } else {
            media.push(
              <div className={styles.mediaCover} key={index + 'thumbnail'}>
                <StaticImage className={styles.playBtn} src="../../images/play-icon.png" alt="play"></StaticImage>
                {article.image && (
                  <div>
                    <GatsbyImage alt={article.title} image={article.image.asset.gatsbyImageData}></GatsbyImage>
                  </div>
                )}
              </div>
            )
          }
        } else {
          media.push(
            <div className={styles.mediaCover} key={index + 'image'}>
              {article.image && (
                <div>
                  <GatsbyImage alt={article.title} image={article.image.asset.gatsbyImageData}></GatsbyImage>
                </div>
              )}
            </div>
          )
        }

        // check if the article has a slug and push it to the articles array
        slug &&
          articles.push(
            <Link to={'/news/' + slug} id="all-card" key={index + slug}>
              <div className={styles.allNewsCard}>
                {media}

                <div className={styles.contentWrap}>
                  {date && <h4>{formatDate(date)}</h4>}
                  <h2>{article.title}</h2>

                  <div className={styles.categories}>
                    {article.topics.forEach((topic, index) => {
                      if (topicCount < 2) {
                        cats.push(<span key={'topic' + index}>{topic.title}</span>)
                      }
                      topicCount++
                    })}

                    {article.categories.forEach((topic, index) => {
                      if (catCount < 2) {
                        cats.push(<span key={'category' + index}>{topic?.title}</span>)
                      }
                      catCount++
                    })}
                    {cats}
                  </div>
                </div>
              </div>
            </Link>
          )
      })
    }

    var pageCountIni = data.articles.totalCount
    var pageCount = Math.ceil(pageCountIni / 9)
    var pagination = []
    var current = 1
    var slug2 = url.split('/news/').pop()
    var parentFilter2 = slug2.substr(0, slug2.indexOf('/'))
    var filteredItem2 = url.split('/news/' + parentFilter2 + '/').pop()
    var filteredItemF2 = ''
    if (url.includes('page')) {
      filteredItemF2 = filteredItem2.substr(0, filteredItem2.indexOf('/')) + '/'
    } else {
      filteredItemF2 = filteredItem + '/'
    }

    //creating paginated pages with numbers on bottom
    var pageItems = []
    for (let i = 0; i < pageCount; i++) {
      pageItems[i + 1] = []
      if (url.includes('topic')) {
        pagination.push(
          <span key={i + 'page'}>
            <Link to={'/news/topic/' + filteredItemF2 + 'page/' + (i + 1)}>{i + 1}</Link>
          </span>
        )
      } else if (url.includes('category')) {
        pagination.push(
          <span key={i + 'page'}>
            <Link to={'/news/category/' + filteredItemF2 + 'page/' + (i + 1)}>{i + 1}</Link>
          </span>
        )
      } else {
        pagination.push(
          <span key={i + 'page'}>
            <Link to={'/news/page/' + (i + 1)}>{i + 1}</Link>
          </span>
        )
      }
      if (isBrowser) {
        if (window.location.href.indexOf('page/' + (i + 1)) > -1) {
          current = i + 1
        }
      }
    }
    //highlight active page
    for (let y = 0; y < pagination.length; y++) {
      if (y + 1 === current) {
        if (url.includes('category')) {
          pagination[y] = (
            <span key={y + 'active'}>
              <Link to={'/news/category/' + filteredItemF2 + 'page/' + (y + 1)}>{y + 1}</Link>
            </span>
          )
        } else if (url.includes('topic')) {
          pagination[y] = (
            <span key={y + 'active'}>
              <Link to={'/news/topic/' + filteredItemF2 + 'page/' + (y + 1)}>{y + 1}</Link>
            </span>
          )
        } else {
          pagination[y] = (
            <span key={y + 'active'}>
              <Link className={styles.activePage} to={'/news/page/' + (y + 1)}>
                {y + 1}
              </Link>
            </span>
          )
        }
      }
    }
    //left and right arrows for pagination
    if (current === 1) {
    } else {
      if (url.includes('topic')) {
        pagination.unshift(
          <span key={'paginatorTopic'}>
            <Link className={styles.navArrow} to={'/news/topic/' + filteredItemF2 + 'page/' + (current - 1)}>
              prev
            </Link>
          </span>
        )
      } else if (url.includes('category')) {
        pagination.unshift(
          <span key={'paginatorCategory'}>
            <Link className={styles.navArrow} to={'/news/category/' + filteredItemF2 + 'page/' + (current - 1)}>
              prev
            </Link>
          </span>
        )
      } else {
        pagination.unshift(
          <span key={'paginatorPage'}>
            <Link className={styles.navArrow} to={'/news/page/' + (current - 1)}>
              prev
            </Link>
          </span>
        )
      }
    }
    if (isBrowser) {
      if (window.location.href.indexOf('/page/' + pageCount) > -1) {
      }
    } else {
      if (url.includes('topic')) {
        pagination.push(
          <span key={'newsTopic'}>
            <Link className={styles.navArrow} to={'/news/topic/' + filteredItemF2 + 'page/' + (current + 1)}>
              next
            </Link>
          </span>
        )
      } else if (url.includes('category')) {
        pagination.push(
          <span key={'newsCategory'}>
            <Link className={styles.navArrow} to={'/news/category/' + filteredItemF2 + 'page/' + (current + 1)}>
              next
            </Link>
          </span>
        )
      } else {
        pagination.push(
          <span key={'newsPage'}>
            <Link className={styles.navArrow} to={'/news/page/' + (current + 1)}>
              next
            </Link>
          </span>
        )
      }
    }
    var pagStyle = {}
    if (pagination.length < 2) {
      pagStyle = { display: 'none' }
    }

    if (isBrowser) {
      if (url.includes('topic') || url.includes('category')) {
        main.push(
          <div className={styles.allNewsWrapper} id="all-news" key={'allNewsTopic'}>
            <div className={styles.allNewsBlockF} id="block-head">
              <p id="block-title">Filtered by {parentFilter}:</p>
              <span className={styles.selectedFilter}>{filtered}</span>
              <Link to="/news" className={styles.filterClear} id="block-title">
                Clear Search Filter
                <RiCloseFill />{' '}
              </Link>
            </div>

            <div className={styles.allNewsGrid}>{articles}</div>

            <div className={styles.pagination} id="pagination" style={pagStyle}>
              {pagination}
            </div>
          </div>
        )
      } else {
        main.push(
          <div className={styles.allNewsWrapper} id="all-news" key={'allNewsPage'}>
            <span className={styles.allNewsBlock} id="block-head">
              all News
            </span>

            <div className={styles.allNewsGrid}>{articles}</div>

            <div className={styles.pagination} id="pagination">
              {pagination}
            </div>
          </div>
        )
      }
    }
  }

  return <>{main}</>
}
