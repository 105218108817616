import React from 'react'
import { graphql } from 'gatsby'
import FilterBreak from '../components/FilterBreak/FilterBreak'
import Footer from '../components/Footer/Footer'
import Layout from '../components/Layout/Layout'
import Nav from "../components/Nav/Nav"
import NewsFilter from '../components/NewsFilter/NewsFilter'
import NewsMain from '../components/NewsMain/NewsMain'
import * as styles from '../styles/News.module.scss'
import Seo from '../components/Seo/Seo'

export const query = graphql`
  query topicF($skip: Int = 0, $pageSize: Int = 9, $topic: [String]) {
    articles: allSanityArticle(
      sort: {fields: [date], order: DESC}
      limit: $pageSize
      skip: $skip
      filter: {topics: {elemMatch: {title: {in: $topic}}}}
    ) {
      totalCount
      nodes {
        id
        title
        slug {
          current
        }
        categories {
          id
          title
        }
        topics {
          id
          title
        }
        date
        excerpt
        image {
          asset {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1600)
          }
        }
        video
        typeCheck
      }
    }
    featured: sanityNewsSettings {
      browserTitle
      seoData {
        title
        description
        image {
          asset {
            url
          }
        }
      }
      featuredArticles {
        id
        title
      }
    }
    sidebar: sanityNewsSettings {
      sidebarArticles {
        id
        title
      }
    }
    topics: allSanityTopic {
      nodes {
        title
        slug {
          current
        }
      }
    }
    categories: allSanityCategory {
      nodes {
        title
        slug {
          current
        }
      }
    }
  }

`

const newsFiltered = props => {
  const { data } = props
  return (
    <Layout>
      <Nav />
      <div className={styles.newsWrapper} id="news-wrapper">
        {/* <h1 id="news-header" className={styles.newsHeader}>
          <span><img src={pincailogo} alt="Pinc AI Logo" /></span>
          <span>Newsfeed</span>
        </h1> */}

        <FilterBreak height={120} />

        <NewsFilter data={data} />

        <NewsMain data={data} />

        <Footer />

      </div>
    </Layout>
  )
}

export default newsFiltered

export const Head = (props) => {
  const { data } = props;
  return (
    <Seo title={data.featured.browserTitle} seo={data.featured.seoData} />
  )
}