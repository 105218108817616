// extracted by mini-css-extract-plugin
export var activePage = "AllNews-module--activePage--32e7c";
export var allNewsBlock = "AllNews-module--allNewsBlock--92348";
export var allNewsBlockF = "AllNews-module--allNewsBlockF--9ccba";
export var allNewsCard = "AllNews-module--allNewsCard--d2469";
export var allNewsGrid = "AllNews-module--allNewsGrid--c515f";
export var allNewsWrapper = "AllNews-module--allNewsWrapper--678d9";
export var categories = "AllNews-module--categories--2503e";
export var contentWrap = "AllNews-module--contentWrap--c0b25";
export var filterClear = "AllNews-module--filterClear--c7bd5";
export var mediaCover = "AllNews-module--mediaCover--61197";
export var navArrow = "AllNews-module--navArrow--2fa63";
export var noResults = "AllNews-module--noResults--64a0d";
export var pagination = "AllNews-module--pagination--393ce";
export var playBtn = "AllNews-module--playBtn--7ea78";
export var selectedFilter = "AllNews-module--selectedFilter--30acc";
export var thumbnail = "AllNews-module--thumbnail--36a71";