import React from 'react'
import AllNews from '../AllNews/AllNews'
import FeaturedNews from '../FeaturedNews/FeaturedNews'
import SideBar from '../SideBar/SideBar'
import * as styles from './NewsMain.module.scss'

export default function NewsMain({ data }) {
  const isBrowser = typeof window !== 'undefined'
  var main = ''

  if (isBrowser) {
    var url = window.location.href

    if (url.includes('topic') || url.includes('category')) {
      main = (
        <div className={styles.newsMainWrapper}>
          <div className={styles.newsMainLayout} id="news-main">
            <div className={styles.leftContent}>
              <AllNews data={data} />
            </div>
            <div className={styles.rightContent}>
              <SideBar data={data} />
            </div>
          </div>
        </div>
      )
    } else if (url.includes('page') && !url.includes('page/1')) {
      main = (
        <div className={styles.newsMainWrapper}>
          <div className={styles.newsMainLayout} id="news-main">
            <div className={styles.leftContent}>
              <AllNews data={data} />
            </div>
            <div className={styles.rightContent}>
              <SideBar data={data} />
            </div>
          </div>
        </div>
      )
    } else {
      main = (
        <div className={styles.newsMainWrapper}>
          <div className={styles.newsMainLayout} id="news-main">
            <div className={styles.leftContent}>
              <FeaturedNews data={data} />
              <AllNews data={data} />
            </div>
            <div className={styles.rightContent}>
              <SideBar data={data} />
            </div>
          </div>
        </div>
      )
    }
  }

  return <>{main}</>
}
